// Borders
.border-between,
.border-between-sm,
.border-between-md,
.border-between-lg,
.border-between-xl {
    > [class*=col-]:not(:first-child):after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 0;
        background: transparent;
        border: 0;
        border-right: 1px solid $navy;
    }
}

.border-between {
    > [class*=col-]:not(:first-child):after {
        border-right: 1px solid $navy;
    }
    &.border-between-2 {
        > [class*=col-]:not(:first-child):after {
            border-left: 1px solid $navy;
        }
    }
}
@include media-breakpoint-up(sm) {
    .border-between-sm {
        > [class*=col-]:not(:first-child):after {
            border-right: 1px solid $navy;
        }
        &.border-between-2 {
            > [class*=col-]:not(:first-child):after {
                border-left: 1px solid $navy;
            }
        }
    }
}
@include media-breakpoint-up(md) {
    .border-between-md {
        > [class*=col-]:not(:first-child):after {
            border-right: 1px solid $navy;
        }
        &.border-between-2 {
            > [class*=col-]:not(:first-child):after {
                border-left: 1px solid $navy;
            }
        }
    }
}
@include media-breakpoint-up(lg) {
    .border-between-lg {
        > [class*=col-]:not(:first-child):after {
            border-right: 1px solid $navy;
        }
        &.border-between-2 {
            > [class*=col-]:not(:first-child):after {
                border-left: 1px solid $navy;
            }
        }
    }
}
@include media-breakpoint-up(xl) {
    .border-between-xl {
        > [class*=col-]:not(:first-child):after {
            border-right: 1px solid $navy;
        }
        &.border-between-2 {
            > [class*=col-]:not(:first-child):after {
                border-left: 1px solid $navy;
            }
        }
    }
}
//.border-between-2 > [class*=col-]:not(:first-child):after {
//    left: -1px;
//    border-right-width: 2px;
//}
.border-between-3 > [class*=col-]:not(:first-child):after {
    left: -2px;
    border-right-width: 3px;
}
.border-between-dashed > [class*=col-]:not(:first-child):after {
    border-right-style: dashed;
}

// Sizes
.min-height-45 {
    min-height: 45px;
}

// Functie item in list
.functie-list-item {
    display: block;
    height: 30px;
    border-bottom: 1px solid $white;
    text-align: center;
    position: relative;

    &.active {
        background-color: $teal;
        color: $navy;
        cursor: pointer;
        font-weight: $font-weight-bold;
        @include transition($transition-base);
    }
    &.active:hover {
        background-color: $teal-light;
    }
    &:not(.active) {
        color: $black;
    }

    .functie-list-item-icon {
        position: absolute;
        margin-top: 0.25rem;
        right: 10px;
    }
}

// Functie familie displays other colors too
.other-familie-colors {
    display: block;
    float: right;
    margin-top: -1rem;
    margin-right: -1rem;
    width: 72px;
    height: 72px;
    &>span {
        display: inline-block;
        width: 36px;
        height: 72px;
    }
}

// Hover effect on text
.text-underline-hover:hover,
.text-hover-parent:hover .text-underline-parent-hover {
    text-decoration: underline;
}

// Special class to hide all bold text in a container
.hide-bold-text strong {
    font-weight: $font-weight-base !important;
}