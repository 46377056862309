//
// Color definitions
//

// default colors
$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;

$grays: ();
// stylelint-disable-next-line scss/dollar-variable-default
$grays: map-merge(
        (
            "100": $gray-100,
            "200": $gray-200,
            "300": $gray-300,
            "400": $gray-400,
            "500": $gray-500,
            "600": $gray-600,
            "700": $gray-700,
            "800": $gray-800,
            "900": $gray-900
        ),
        $grays
);

$blue: #007bff;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #e83e8c;
$red: #dc3545;
$green: #28a745;
$teal: #27c1ad;
$cyan: #17a2b8;

// project colors
$navy: #00325f;
$teal: #27c1ad;
$teal-light: #30D5BF;
$teal-dark: #1e9787;

$lightblue: #00AFF5;
$lightblue-light: #3DC8FF;
$lightblue-extralight: #d8f2ff;

$yellow: #ffdd00;
$yellow-light: #ffed78;
$yellow-extralight: #fdf9d9;

$orange: #ff8100;
$orange-light: #ff9d19;
$orange-extralight: #f9eacd;

// theme colors
$primary: $teal;
$secondary: $navy;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: $gray-100;
$dark: $gray-800;

// stylelint-disable-next-line scss/dollar-variable-default
$colors: (
    "blue": $blue,
    "indigo": $indigo,
    "purple": $purple,
    "pink": $pink,
    "red": $red,
    "green": $green,

    "light": $light,
    "dark": $dark,
    "silver": $gray-200,
    "gray": $gray-600,
    "gray-dark": $gray-800,
    "white": $white,
    "black": $black,

    "cyan": $cyan,
    "navy": $navy,
    "teal": $teal,
    "teal-light": $teal-light,
    "teal-dark": $teal-dark,
    "lightblue": $lightblue,
    "lightblue-light": $lightblue-light,
    "lightblue-extralight": $lightblue-extralight,
    "yellow": $yellow,
    "yellow-light": $yellow-light,
    "yellow-extralight": $yellow-extralight,
    "orange": $orange,
    "orange-light": $orange-light,
    "orange-extralight": $orange-extralight,
);