.navbar-brand {
    font-weight: $navbar-brand-font-weight;

    @include media-breakpoint-down(md) {
        margin-right: 0.25rem;
        font-size: 1.1em;
    }
}

.btn-nav-link {
    @extend .nav-link;
    @extend .text-left;

    &:focus {
        outline: -webkit-focus-ring-color auto 1px;
    }
}

// Generate `.d-navbar-collapsed-*` and `.d-navbar-expanded-*` responsive classes for configuring
// where your navbar collapses.
.navbar-expand {
    @each $breakpoint in map-keys($grid-breakpoints) {
        $next: breakpoint-next($breakpoint, $grid-breakpoints);
        $infix: breakpoint-infix($next, $grid-breakpoints);

        &#{$infix} {
            @include media-breakpoint-down($breakpoint) {
                @each $value in $displays {
                    .d-navbar-collapsed-#{$value} {
                        display: $value !important;
                    }
                }

                .d-navbar-collapsed-sr-only {
                    @include sr-only();
                }

                .d-navbar-collapsed-sr-only-focusable {
                    @include sr-only-focusable();
                }
            }

            @include media-breakpoint-up($next) {
                @each $value in $displays {
                    .d-navbar-expanded-#{$value} {
                        display: $value !important;
                    }
                }

                .d-navbar-expanded-sr-only {
                    @include sr-only();
                }

                .d-navbar-expanded-sr-only-focusable {
                    @include sr-only-focusable();
                }
            }
        }
    }
}